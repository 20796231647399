// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-beginners-tsx": () => import("./../../../src/pages/beginners.tsx" /* webpackChunkName: "component---src-pages-beginners-tsx" */),
  "component---src-pages-bots-tsx": () => import("./../../../src/pages/bots.tsx" /* webpackChunkName: "component---src-pages-bots-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-404-tsx": () => import("./../../../src/pages/resources/404.tsx" /* webpackChunkName: "component---src-pages-resources-404-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-spotlights-404-tsx": () => import("./../../../src/pages/spotlights/404.tsx" /* webpackChunkName: "component---src-pages-spotlights-404-tsx" */),
  "component---src-pages-spotlights-index-tsx": () => import("./../../../src/pages/spotlights/index.tsx" /* webpackChunkName: "component---src-pages-spotlights-index-tsx" */),
  "component---src-templates-resource-home-tsx": () => import("./../../../src/templates/resourceHome.tsx" /* webpackChunkName: "component---src-templates-resource-home-tsx" */),
  "component---src-templates-resource-page-tsx": () => import("./../../../src/templates/resourcePage.tsx" /* webpackChunkName: "component---src-templates-resource-page-tsx" */),
  "component---src-templates-spotlight-tsx": () => import("./../../../src/templates/spotlight.tsx" /* webpackChunkName: "component---src-templates-spotlight-tsx" */)
}

