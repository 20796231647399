import React from "react"

const DiscordLogo: React.FC = (props) => {
  return (
    <svg fill="none" viewBox="0 0 43 48" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.17 21.01c0-1.19 1.04-2.17 2.34-2.17 1.3 0 2.36.98 2.34 2.17 0 1.2-1.03 2.18-2.34 2.18-1.28 0-2.34-.98-2.34-2.18zm8.37 0c0-1.19 1.03-2.17 2.34-2.17 1.3 0 2.34.98 2.34 2.17 0 1.2-1.03 2.18-2.34 2.18-1.28 0-2.34-.98-2.34-2.18z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.6 0h32.2c2.71 0 4.92 2.2 4.92 4.93v42.9l-5.16-4.55-2.9-2.68-3.08-2.84 1.27 4.42H5.6a4.92 4.92 0 01-4.92-4.92V4.93A4.92 4.92 0 015.6 0zm21.33 29.3c.72.9 1.59 1.93 1.59 1.93 5.3-.17 7.35-3.63 7.35-3.63 0-7.7-3.46-13.95-3.46-13.95-3.46-2.58-6.75-2.5-6.75-2.5l-.34.38c4.08 1.24 5.98 3.03 5.98 3.03a19.64 19.64 0 00-12.49-2.2 18.1 18.1 0 00-6.87 2.2s2-1.89 6.32-3.13l-.24-.29s-3.29-.07-6.75 2.51c0 0-3.46 6.25-3.46 13.95 0 0 2.02 3.46 7.33 3.63 0 0 .89-1.08 1.6-1.98-3.04-.91-4.2-2.83-4.2-2.83a10 10 0 00.78.48c.03.03.07.04.1.06l.11.06a20.23 20.23 0 005.28 1.84 16.9 16.9 0 009.7-1c.85-.31 1.78-.77 2.77-1.41 0 0-1.2 1.96-4.35 2.84z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default DiscordLogo
