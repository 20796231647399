import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import { Helmet } from "react-helmet"

interface ISEOProps {
  readonly description?: string
  readonly lang?: string
  readonly meta?: any[]
  readonly keywords?: string[]
  readonly title: string
  readonly subCategory?: string | null
}

export const SEO: FC<ISEOProps> = ({
  description,
  lang = "en",
  meta = [],
  keywords = [],
  title,
  subCategory,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={subCategory ? `${title} - ${subCategory}` : title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}
